// Authentication cookie helpers
//
// Websocket session is a pair [userName, sessionId].
// It is serialized as a string similar to "yallie:0b6de58c-5ebe-4655-bcee-894f900558b4".
// The string is stored in sessionStorage, so it's lost when a browser tab is closed.
// However, sessionStorage contents is not shared between open tabs.
// So the authentication is lost when a user opens a link in a new tab (ctrl+click).
// To overcome this limitation, session data is also copied to a cookie.
// Browser window has an event handler which deletes the cookie when a window is closed.
//
// This combined cookie+sessionStorage solution ensures that:
// 1. Refreshing a browser tab (f5) keeps the current session open
// 2. Opening a link in a new tab shares the same session between tabs
// 3. Closing all open tabs deletes session information
//
// To use the solution:
// 1. call initSessionCookie function once when the application is started up
// 2. use loadSessionId and saveSessionId functions to load and save session information
// 3. use clearSessionId to clear the session without closing the browser tab (logout button)

const sessionCookieName = "ultimaSid"
const counterName = "ultimaCounter"

function getCookieValue(cookieName: string) {
    return (document.cookie.split("; ").find(r => r.startsWith(cookieName)) || "").split("=")[1]
}

function getCounter() {
    const counter = getCookieValue(counterName)
    if (counter) {
        return parseInt(counter, 10)
    }

    return 0
}

function setCounter(value: number) {
    document.cookie = `${counterName}=${value}`
}

function changeCounter(inc: number) {
    let current = getCounter()
    current = current + inc
    setCounter(current)
    return current
}

export function initSessionCookie() {
    // window counter
    changeCounter(+1)
    window.onbeforeunload = () => {
        // if no more open tabs left
        if (changeCounter(-1) === 0) {
            // clean cookie but not sessionStorage
            document.cookie = `${sessionCookieName}=`
        }
    }
}

export function clearSessionId() {
    // clean both cookie and the sessionStorage
    document.cookie = `${sessionCookieName}=`
    sessionStorage.setItem(sessionCookieName, "")
}

export function saveSessionId(sid: [string, string]) {
    const cookieValue = `${sid[0]}:${sid[1]}`
    document.cookie = `${sessionCookieName}=${cookieValue}`
    sessionStorage.setItem(sessionCookieName, cookieValue)
}

function tryLoadSessionId() {
    let cookieValue = sessionStorage.getItem(sessionCookieName)
    if (cookieValue) {
        return cookieValue
    }

    cookieValue = getCookieValue(sessionCookieName)
    return cookieValue
}

export function loadSessionId() {
    const cookieValue = tryLoadSessionId()
    if (cookieValue) {
        const parts = cookieValue.split(/:/)
        const sid: [string, string] = [parts[0], parts[1]]

        // save to cookie and local storage
        saveSessionId(sid)
        return sid
    }

    // no saved session
    return undefined
}

